import * as React from "react"
import { graphql } from "gatsby"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Layout from "../../components/layout"
import SEOComponent from "../../components/seo"
import { Hero } from "../../components/hero"
import PageCTA from "../../components/pageCTA"

export const query = graphql`
  query JobTemplateQuery($id: String) {
    heroImage: contentfulAsset(id: {eq: "5ce7062d-d187-5d3b-96e7-8a9f288a5d44"}) {
      gatsbyImageData(layout: FULL_WIDTH)
    }
    contentfulJob(id: {eq: $id}) {
      jobTitle
      permalink
      jobType
      jobDescription {
        raw
      }
    }
  }
`

const options = {
  renderNode: {
    [BLOCKS.LIST_ITEM]: node => <li>{node.content[0].content[0].value}</li>,
  },
}

const JobTemplate = ({ data, pageContext }) => {
  const banner = data.heroImage.gatsbyImageData
  const { jobTitle, jobType, jobDescription } = data.contentfulJob || null

  return (
    <Layout>
      <SEOComponent title={jobTitle} description={`Strong Foundations CT ${jobTitle} job profile`} />
      <Hero image={banner} header={jobTitle} />
      <div className="container my-4 mx-auto pt-0 px-4 pb-6">
        <section>
          <div className="prose prose-sm md:prose lg:prose-lg mx-auto my-4 pt-0 px-4 pb-6">
            <h2 className="text-3xl font-serif">{jobTitle}</h2>
            <p className="text-2xl font-serif text-gray-500">{jobType}</p>
            {jobDescription &&
              <div className="space-y-4">{renderRichText(jobDescription, options)}</div>
            }
          </div>
        </section>

        <PageCTA
          h2="Ready to apply?"
          buttonText="Email us"
          mailto="info@strongfoundationsct.com"
        >
          <p className="font-serif text-gray-500 text-xl" >
            Click the button to email us.
          </p>
        </PageCTA>
      </div>
    </Layout>
  )
}

export default JobTemplate
